import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import WorkoutTimerIcon from "../images/WorkoutTimerIcon.png"
import AppStore from "../images/AppStoreBadge.svg"
import S1 from "../images/sreeenshots/S1.png"
import S2 from "../images/sreeenshots/S2.png"
import S3 from "../images/sreeenshots/S3.png"
import PV1 from "../images/sreeenshots/HowItWorks.mp4"
import PV2 from "../images/sreeenshots/SoundSetup.mp4"

const Apps = () => (
    <Layout>
        <Seo title="Application" />
        <div className="main">
            <h1>Applicaion</h1>
            <section className="contents">
                <h2>Workout Timer - iPhone App</h2>
                <img className="padding-top_10vh padding-bottom_10vh" src={WorkoutTimerIcon} alt="Workout Timer Icon"/>
                <a href="https://apps.apple.com/jp/app/id438090554"><img className="appstore" src={AppStore} alt="App Store Icon"/></a>
                <h3>筋トレやヨガに！</h3>
                <p>
                    「Workout Timer」は筋トレのような回数を繰り返す運動の「回数を数える」をサポートするアプリです。
                </p>

                <p>
                    例えば、筋トレの際に筋肉に集中しすぎて回数を忘れてしまったとかはありませんか？
                    そんな時にはWorkout Timer。これを使えば回数を数えることをせずに、ただただ筋肉に集中できるのです。
                    そして、セット数とインターバルの設定もできるので、規則正しく運動でき、休み時間も休むことに集中できます。
                </p>

                <p>スロートレーニングのように「回数と秒数」を気にしないといけない場合には、Workout Timerは特に威力を発揮します。</p>

                <p>
                    また、筋肉の収縮と伸張と停止の秒数を個別に設定できるので、負荷のかけ具合を柔軟に設定できます。
                    設定画面では「Fade」となっている部分で秒数を指定できます。Fade1を収縮、Fade2を停止、Fade3を伸張のようにして下さい。
                </p>

                <p>Workout Timerは、発生するサウンドの音量をユーザーが調節できるので、音楽を聴きながらの運動にも最適です。</p>

                <h3>設定例</h3>
                <table className="text_left width_100percent">
                    <tr>
                        <td>名前</td><td>腹筋</td>
                    </tr>
                    <tr>
                        <td>1セットの回数</td><td>12</td>
                    </tr>
                    <tr>
                        <td>セット数</td><td>3</td>
                    </tr>
                    <tr>
                        <td>Fade1</td><td>3</td>
                    </tr>
                    <tr>
                        <td>Fade2</td><td>1</td>
                    </tr>
                    <tr>
                        <td>Fade3</td><td>3</td>
                    </tr>
                    <tr>
                        <td>インターバル</td><td>20</td>
                    </tr>
                </table>
                <p>
                    上記のようにセットすると、
                    <div className="darkbox">(3秒 + 1秒 + 3秒) x 12回 x 3セット + 20秒 x 2<br/> = 292秒<br/>= 4分52秒</div>
                    で、4分52秒の間腹筋に集中できます。
                </p>

                <h3>スクリーンショット</h3>
                <div className="screenshot">
                    <img src={S1} alt="メイン画面"/>{/*<figure>= メイン画面 =</figure>*/}
                    <img src={S2} alt="設定画面"/>{/*<figure>= 設定画面 =</figure>*/}
                    <img src={S3} alt="音量調節"/>{/*<figure>= 音量調節 =</figure>*/}
                </div>

                <h3>プレビュー動画</h3>
                <video controls width="250">
                    <source src={PV1} type="video/mp4"/>
                    Sorry, your browser doesn't support embedded videos.
                </video>
                <figure>= 動作 =</figure>
                <video controls width="250">
                    <source src={PV2} type="video/mp4"/>
                    Sorry, your browser doesn't support embedded videos.
                </video>
                <figure>= 設定 =</figure>
            </section>
        </div>
    </Layout>
  )
  
  export default Apps